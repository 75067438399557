'use client';

import '~/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

import Head from 'next/head';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { api } from '~/utils/api';

import { UserProvider } from '@auth0/nextjs-auth0/client';

function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <Head>
        <link rel="stylesheet" href="https://fonts.cdnfonts.com/css/general-sans" />
      </Head>
      <body>
        <UserProvider>{children}</UserProvider>
        <ToastContainer hideProgressBar />
      </body>
    </html>
  );
}

export default api.withTRPC(RootLayout);
